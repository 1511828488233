const Languages = [
"Abkhaz",
"Adyghe",
"Afrikaans",
"Akan",
"Albanian",
"American Sign Language",
"Amharic",
"Arabic",
"Aragonese",
"Aramaic",
"Armenian",
"Assamese",
"Aymara",
"Azerbaijani",
"Balinese",
"Basque",
"Betawi",
"Bosnian",
"Breton",
"Bulgarian",
"Cantonese",
"Catalan",
"Cherokee",
"Chickasaw",
"Chinese",
"Coptic",
"Cornish",
"Corsican",
"Crimean Tatar",
"Croatian",
"Czech",
"Danish",
"Dari",
"Dutch",
"Dawro",
"Esperanto",
"Estonian",
"Ewe",
"Farsi",
"Fiji Hindi",
"Filipino",
"Finnish",
"French",
"Galician",
"Georgian",
"German",
"Greek, Modern",
"Ancient Greek",
"Greenlandic",
"Haitian Creole",
"Hawaiian",
"Hebrew",
"Hindi",
"Hungarian",
"Icelandic",
"Indonesian",
"Inuktitut",
"Interlingua",
"Irish",
"Italian",
"Japanese",
"Javanese",
"Kabardian",
"Kalasha",
"Kannada",
"Kashubian",
"Khmer",
"Kinyarwanda",
"Korean",
"Kurdish/Kurdî",
"Ladin",
"Latgalian",
"Latin",
"Lingala",
"Livonian",
"Lojban",
"Lower Sorbian",
"Low German",
"Macedonian",
"Malay",
"Malayalam",
"Mandarin",
"Manx",
"Maori",
"Mauritian Creole",
"Middle English",
"Middle Low German",
"Min Nan",
"Mongolian",
"Norwegian",
"Oriya",
"Pangasinan",
"Papiamentu",
"Pashto",
"Persian",
"Pitjantjatjara",
"Polish",
"Portuguese",
"Proto-Slavic",
"Punjabi",
"Quenya",
"Rajasthani",
"Rapa Nui",
"Romanian",
"Russian",
"Sanskrit",
"Scots",
"Scottish Gaelic",
"Semai",
"Serbian",
"Serbo-Croatian",
"Slovak",
"Slovene",
"Spanish",
"Sinhalese",
"Swahili",
"Swedish",
"Tagalog",
"Tajik",
"Tamil",
"Tarantino",
"Telugu",
"Thai",
"Tok Pisin",
"Turkish",
"Twi",
"Ukrainian",
"Upper Sorbian",
"Urdu",
"Uyghur",
"Uzbek",
"Venetian",
"Vietnamese",
"Vilamovian",
"Volapük",
"Võro",
"Welsh",
"Xhosa",
"Yiddish",
"Zazaki",
"Zulu"
]

module.exports = Languages;